<template>
    <div class="event-timestamp-wrapper">
        <span class="event-time-label">{{ eventTime }}</span>
        <div class="timeline-wrapper">
            <div class="timeline-line-group">
                <span class="timeline-line-segment" />
                <i class="fa-solid fa-circle-dot" style="color: #1d3f67" />
                <span class="timeline-line-segment" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
       eventTime: {
           type: String,
           required: true,
       },
       eventDate: {
           type: String,
           required: false,
       },
    },
    data() {
        return {
            // Add any additional data properties here
        };
    },
    methods: {
        // Add any methods you need here
    },
    // Add any computed properties here if needed
};
</script>

<style scoped>
.event-timestamp-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
}
.event-time-label {
    color: #1d3f67;
    font-size: 12px;
    display: inline-block;
    border-radius: 1rem;
    word-break: break-all;
    white-space: pre;
    margin-right: 1rem;
}

.timeline-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.timeline-line-group {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.timeline-line-segment {
    width: 1px;
    height: 100%;
    border-radius: 50px;
    border: 1px solid #b4c8e0;
}
</style>
