import SupplierInvoiceList2 from "./list/supplierInvoiceList2.vue";
import HighValueSupplierInvoice from "./highValueSupplierInvoice/highValueSupplierInvoiceList.vue";
import SupplierInvoice from "./highValueSupplierInvoice/supplierInvoiceReadOnlyForm.vue";
import SupplierInvoiceR from "./supplierInvoice.vue";

const routes = [
    {
        path: "/supplierInvoiceList",
        name: "supplierInvoiceList",
        component: SupplierInvoiceList2,
        meta: {
            forAuth: true,
            title: "Leverantörsfaktura",
        },
    },
    {
        path: "/highValueSupplierInvoice",
        name: "highValueSupplierInvoice",
        component: HighValueSupplierInvoice,
        meta: {
            forAuth: true,
            title: "Leverantörsfaktura",
        },
    },
    {
        path: "/supplierInvoice",
        name: "supplierInvoice",
        component: SupplierInvoice,
        meta: {
            forAuth: true,
            title: "Leverantörsfaktura",
        },
    },
    {
        path: "/supplierInvoice/:supplierInvoiceId",
        name: "SupplierInvoice",
        component: SupplierInvoiceR,
        meta: {
            forAuth: true,
            title: "SupplierInvoice",
        },
    },
];
export default routes;
