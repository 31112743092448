<template>
    <Card style="margin: 2.5em 0" title="Versamhetsbeskrivning" width="100%">
        <table>
            <thead>
                <tr>
                    <th>Beskrivning</th>
                    <th>Aktuell</th>
                    <th v-if="status !== 'noActionRequired'">Ny</th>
                    <th>Historiskt senaste 12 mån</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(question, index) in questionKeys" :key="index">
                    <td :title="getDescription('question', question)">
                        {{ formatLabel(question) }}
                    </td>
                    <td :title="getDescription('oldCompanyInfo', question)">
                        {{ formatAnswer("old", question, formattedOldAnswers[question]) }}
                    </td>
                    <td v-if="status !== 'noActionRequired'" :title="getDescription('newCompanyInfo', question)">
                        {{ formatAnswer("new", question, formattedNewAnswers[question]) }}
                    </td>
                    <td :title="getDescription('actualCompanyInfo', question)">
                        {{ formatAnswer("syna", question, formattedActualAnswers[question]) }}
                    </td>
                </tr>
            </tbody>
        </table>
    </Card>
</template>

<script>
import Enum from "../kycByClient.enum.js";
import Card from "@/components/Card.vue";
export default {
    props: {
        answers: {
            type: Object,
            required: true,
        },
        status: {
            type: String,
            required: true,
            default: "",
        },
    },
    components: {
        Card,
    },
    data() {
        return {
            formattedOldAnswers: {},
            formattedNewAnswers: {},
            formattedActualAnswers: {},
            sniCodes: [],
            titleDescriptions: {
                expectedTurnover: {
                    question: "Förväntad omsättning de närmaste 12 månaderna (för ny och gammal data) och faktisk omsättning de senaste 12 månaderna (för historiskt senast 12 mån-data).",
                    oldCompanyInfo: "Klients förväntade omsättning de närmaste 12 månaderna",
                    newCompanyInfo: "Klients förväntad omsättning de närmaste 12 månaderna",
                    actualCompanyInfo: "Faktisk omsättning de senaste 12 månaderna",
                },
                businessDescription: {
                    question: "Företagsverksamhetsbeskrivning",
                },
                cashHandling: {
                    question: "Hantering av kontanter i verksamheten",
                },
            },
        };
    },
    mounted() {
        this.formatCompanyInfo();
    },
    methods: {
        getDescription(columnKey, question) {
            return this.titleDescriptions[question]?.[columnKey] || null;
        },
        formatLabel(key) {
            const labels = {
                expectedTurnover: "Omsättning de närmaste 12 månaderna",
                businessDescription: "Företagsverksamhetsbeskrivning",
                cashHandling: "Kontanthantering",
                supplierCountries: "Leverantörer utanför Sverige",
                sellingMethod: "Försäljningssätt för varor/tjänster",
                customerCompanyRegion: "Företagskund regioner",
                customerPrivateRegion: "Privatkund regioner",
                customerCountries: "Kunder utanför Sverige",
            };
            return labels[key] || key;
        },
        handleYesNo(booleanValue) {
            return booleanValue ? "Ja" : "Nej";
        },
        formatAnswer(type, question, answer) {
            // Handle boolean answers
            if (typeof answer === "boolean") {
                return this.handleYesNo(answer);
            }
            // Handle empty answers
            if (!answer || (Array.isArray(answer) && answer.length === 0)) return "-";

            // Show text and/or actual amount last 12 months for turnover field
            if (question === "expectedTurnover") {
                const turnOverText = type === "syna" ? this.$options.filters.swedishNumberFormat(answer, true) + " SEK" : Enum.kycByClientNewTurnOver[answer] + " SEK";
                return turnOverText;
            }
            return answer;
        },
        formatCompanyInfo() {
            const removeUnwantedFields = data => {
                if (!data) return null;

                // eslint-disable-next-line no-unused-vars
                const { email, phoneNumber, address, address2, postalCode, city, supplierInSweden, customerOutsideEu, ...filteredData } = data;

                return filteredData;
            };

            this.formattedOldAnswers = removeUnwantedFields(this.answers.oldCompanyInfo);
            this.formattedNewAnswers = removeUnwantedFields(this.answers.newCompanyInfo);
            this.formattedActualAnswers = removeUnwantedFields(this.answers.actualCompanyInfo);
        },
    },
    computed: {
        questionKeys() {
            return Object.keys(this.formattedOldAnswers || {});
        },
    },
};
</script>

<style scoped>
thead {
    border-bottom: 1px solid #b4c8e0;
}
table {
    width: 100%;
    overflow: hidden;
}

tbody tr {
    border-bottom: 1px solid #b4c8e0;
    transition: background-color 0.2s ease;
}

td,
th {
    white-space: pre-line;
    text-wrap: nowrap;
    padding-bottom: 1em;
    padding-right: 1em;
}

td {
    padding-top: 1em;
    font-size: 12px;
    color: #1d3f67;
    white-space: pre-line;
    word-break: break-word;
    width: 25%;
}

tr > th {
    font-weight: bold;
    color: #1d3f67;
    font-size: 14px;
}
</style>
