<template>
    <Card class="owners-container-card" v-if="owners" title="Verkliga Huvudmän">
        <div v-loading="loading" class="kyc-owners-container">
            <div class="kyc-owners-category">
                <div class="kyc-owners-category-header">Aktuell</div>
                <OwnerCard v-for="(owner, index) in paddedOldOwners" :key="index" :owner="owner" owner-card-type="current" :client-id="clientId" />
            </div>
            <div v-if="!isStatusNoActionRequired" class="kyc-owners-category">
                <div class="kyc-owners-category-header">Ny</div>
                <OwnerCard v-for="(owner, index) in paddedNewOwners" :key="index" :owner="owner" owner-card-type="new" :client-id="clientId" />
            </div>
            <div class="kyc-owners-category">
                <div class="kyc-owners-category-header">Syna</div>
                <OwnerCard v-for="(owner, index) in paddedThirdPartyOwners" :key="index" :owner="owner" owner-card-type="third-party" :client-id="clientId" />
            </div>
        </div>
        <div class="handle-owners-container">
            <el-button type="primary" @click="handleManageOwnersDialog()">Lägg till delägare</el-button>
            <div v-if="oldComment || newComment" class="all-comments-container">
                <div v-if="!oldComment" class="previous-added-comments-container" />
                <div title="Kommentarer kring klientens aktuella huvudmän" v-if="oldComment" class="previous-added-comments-container">
                    <div :style="'background-color: ' + (isStatusNoActionRequired ? '#1d3f67' : '#808080')" class="circle-icon">
                        <i class="fa-solid fa-comment-dots" style="color: white" />
                    </div>
                    <div class="name-comment-container">
                        <div class="added-comment-name">
                            <span class="comment-name">{{ oldComment.commentBy }}</span> <span class="comment-date">{{ formatCommentDate(oldComment.commentDate) }}</span>
                        </div>
                        <div class="added-comment-text">{{ oldComment.comment }}</div>
                    </div>
                </div>
                <div title="Kommentarer kring klientens nya huvudmän " v-if="newComment && !isStatusNoActionRequired" class="added-comments-container">
                    <div class="circle-icon">
                        <i class="fa-solid fa-comment-dots" style="color: white" />
                    </div>
                    <div class="name-comment-container">
                        <div class="added-comment-name">
                            <span class="comment-name">{{ newComment.commentBy }}</span> <span class="comment-date">{{ formatCommentDate(newComment.commentDate) }}</span>
                        </div>
                        <div class="added-comment-text">{{ newComment.comment }}</div>
                    </div>
                </div>
            </div>
            <div class="owner-information-handling-buttons">
                <el-button @click="commentOwners()">Kommentera verkliga huvudmän</el-button>
                <el-button :loading="loadingSaveOwnershipStructureFile" @click="addOrgTree()">Bifoga org träd<i class="el-icon-upload el-icon-right ml-1"/></el-button>
                <el-button v-if="hasOwnershipStructureFile" @click="viewOrgTree" type="primary">Visa org träd</el-button>
                <input type="file" ref="orgTreeInput" accept=".pdf,.jpg,.jpeg,.png" style="display: none;" @change="handleOrgTreeUpload" />
            </div>
            <div v-if="showCommentOwners" class="add-comment-container">
                <div class="input-container">
                    <label class="comment-owners-header">Kommentera delägare</label>
                    <textarea class="comment-input" placeholder="Skriv kommentar" rows="2" type="text" v-model="commentInput" />
                    <div class="comment-buttons">
                        <el-button v-if="!loadingSetComment" @click="showCommentOwners = false">Avbryt</el-button>
                        <el-button :loading="loadingSetComment" :disabled="commentInput.length < 3" type="success" @click="setComment()">Skicka</el-button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="owners" class="owner-statistics">
            <div class="statistic-container">
                <div>
                    <label class="statistic-label">Summering av andelar: </label>
                    <span class="statistic-value">{{ calculateShareTotal(owners.oldOwnersList) }}%</span>
                </div>
                <div>
                    <label class="statistic-label">Differens: </label>
                    <span class="statistic-value">{{ calculateShareDifferens(owners.oldOwnersList) }}%</span>
                </div>
            </div>
            <div v-if="!isStatusNoActionRequired" class="statistic-container">
                <div>
                    <label class="statistic-label">Summering av andelar: </label>
                    <span class="statistic-value">{{ calculateShareTotal(owners.newOwnersList) }}%</span>
                </div>
                <div>
                    <label class="statistic-label">Differens: </label>
                    <span class="statistic-value">{{ calculateShareDifferens(owners.newOwnersList) }}%</span>
                </div>
            </div>
        </div>
        <kyc-manage-owners-dialog
            :company-type="companyType"
            :client-id="clientId"
            @close="handleManageOwnersDialog"
            @newOwnerAdded="refreshListData"
            :owners-handling-dialog-visible.sync="showManageOwnersDialog"
        />
    </Card>
</template>

<script>
import Api from "../kycByClient.api.js";
import OwnerCard from "./kycByClientNewOwnerCard.vue";
import kycByClientNewOwnersDialog from "./kycByClientNewOwnersDialog.vue";
import moment from "moment";
import Card from "@/components/Card.vue";
export default {
    components: {
        OwnerCard,
        Card,
        "kyc-manage-owners-dialog": kycByClientNewOwnersDialog,
    },
    name: "KycByClientNewOwners",
    data() {
        return {
            owners: [],
            loading: false,
            loadingSetComment: false,
            showManageOwnersDialog: false,
            companyType: undefined,
            hasOwnershipStructureFile: false,
            loadingSaveOwnershipStructureFile: false,
            showCommentOwners: false,
            commentInput: "",
            newComment: null,
            oldComment: null,
        };
    },
    async created() {
        this.loading = true;
        this.owners = await Api.getClientKycOwners(this.clientId);
        this.hasOwnershipStructureFile = this.owners.hasOwnershipStructureFile;
        this.companyType = this.owners.companyType;
        this.oldComment = this.owners.oldComment;
        this.newComment = this.owners.newComment;
        this.loading = false;
    },
    props: {
        clientId: {
            type: String,
            required: true,
        },
        status: {
            type: String,
            required: true,
            default: "",
        },
    },
    methods: {
        async setComment() {
            try {
                this.loadingSetComment = true;
                const isDraft = this.isStatusNoActionRequired ? false : true;
                const response = await Api.addOwnersComment(this.clientId, isDraft, { comment: this.commentInput });
                if (response) {
                    this.owners = await Api.getClientKycOwners(this.clientId);
                    this.oldComment = this.owners.oldComment;
                    this.newComment = this.owners.newComment;
                    this.showCommentOwners = false;
                    this.commentInput = "";
                    this.loadingSetComment = false;
                    this.$emit("commentAdded");
                    this.$notify.success({ title: "Kommentar tillagd", message: "Din kommentar har sparats och ägarinformationen har uppdaterats." });
                }
            } catch (error) {
                this.loadingSetComment = false;
                this.$notify.error({
                    title: "Ett fel inträffade",
                    message: "Kommentaren kunde inte sparas. Försök igen senare.",
                });
            }
        },
        commentOwners() {
            this.showCommentOwners = !this.showCommentOwners;
        },
        formatCommentDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },
        addOrgTree() {
            this.$refs.orgTreeInput.click();
        },
        async handleOrgTreeUpload(event) {
            const file = event.target.files[0];
            if (file) {
                const convertedToBase64 = file.type !== "application/pdf" ? await this.fileToBase64(file) : await this.pdfToBase64(file);
                const filePayload = {
                    fileName: file.name,
                    base64Content: convertedToBase64,
                    fileType: this.getFileType(file.type),
                };
                this.saveOwnershipStructureFile(this.clientId, filePayload);
            }
            event.target.value = "";
        },
        async saveOwnershipStructureFile(clientId, filePayload) {
            try {
                this.loadingSaveOwnershipStructureFile = true;
                const response = await Api.saveOwnershipStructureFile(clientId, filePayload);
                if (response == false) {
                    this.loadingSaveOwnershipStructureFile = false;
                    this.$notify.error({ title: `Ett fel uppstod vid uppladdning av organisationsträd ` });
                    return;
                } else {
                    this.loadingSaveOwnershipStructureFile = false;
                    this.hasOwnershipStructureFile = true;
                    this.$notify.success({ title: "Organisationsträd uppladdat" });
                }
            } catch (error) {
                this.$notify.error({ title: `Ett fel uppstod vid uppladdning av organisationsträd ` });
            }
        },
        getFileType(fileType) {
            switch (fileType) {
                case "application/pdf":
                    return 1;
                case "image/jpeg":
                    return 4;
                case "image/png":
                    return 5;
                default:
                    return 0;
            }
        },
        async fileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        pdfToBase64(file) {
            return new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = function() {
                    const base64 = btoa(String.fromCharCode.apply(null, new Uint8Array(reader.result)));
                    resolve(base64);
                };
            });
        },
        viewOrgTree() {
            var token = this.$auth.getToken();
            var headers = "?token=" + token;
            var base = this.axios.defaults.baseURL;
            var url = `/kycByClient/${this.clientId}/ownershipStructureFile`;
            window.open(base + url + headers, `_blank`, `width=800, height=800`);
        },
        async refreshListData() {
            this.handleManageOwnersDialog();
            this.loading = true;
            this.owners = await Api.getClientKycOwners(this.clientId);
            this.$emit("ownerAdded");
            this.loading = false;
        },
        handleManageOwnersDialog() {
            this.showManageOwnersDialog = !this.showManageOwnersDialog;
        },
        calculateShareDifferens(owners) {
            const ownersTotal = this.calculateShareTotal(owners);
            const oneHundred = 100;
            const difference = Number(oneHundred - ownersTotal).toFixed(1);

            return parseFloat(difference);
        },
        calculateShareTotal(owners) {
            if (!owners) return 0;
            return owners.reduce((acc, owner) => acc + (owner ? owner.ownerShare : 0), 0);
        },
        padList(list) {
            if (list === undefined) return;
            const placeholder = { isPlaceholder: true };
            // Replace the null values in the list with placeholders
            // Pad the list with placeholders to match the length of the longest list
            return [...list.map(item => (item === null ? placeholder : item)), ...Array(this.maxOwnersLength - list.length).fill(placeholder)];
        },
    },
    computed: {
        isStatusNoActionRequired() {
            return this.status === "noActionRequired";
        },
        maxOwnersLength() {
            return Math.max(this.owners.oldOwnersList.length, this.owners.newOwnersList.length, this.owners.thirdPartyOwnersList.length);
        },
        paddedOldOwners() {
            return this.padList(this.owners.oldOwnersList);
        },
        paddedNewOwners() {
            return this.padList(this.owners.newOwnersList);
        },
        paddedThirdPartyOwners() {
            return this.padList(this.owners.thirdPartyOwnersList);
        },
    },
};
</script>

<style scoped>
@import url("https://use.typekit.net/otv3cvl.css");

.owners-container-card {
    margin: 2.5em 0px;
}

.all-comments-container {
    display: flex;
    gap: 3rem;
    width: 100%;
    margin-bottom: 3rem;
    margin-top: 2rem;
}

.added-comment-name {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.comment-name {
    color: #1d3f67;
    font-weight: bold;
    font-size: 12px;
}

.comment-date {
    color: #808080;
    font-size: 12px;
}

.added-comment-text {
    color: #1d3f67;
    font-size: 12px;
}

.name-comment-container {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}

.previous-added-comments-container {
    display: flex;
    width: 33%;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1em;
}

.added-comments-container {
    display: flex;
    width: 33%;
    justify-content: center;
    align-items: center;
    padding-right: 3em;
    border-radius: 18px;
}

.circle-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: white;
    background-color: #1d3f67;
    text-align: center;
    line-height: 30px;
}

.add-comment-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 1rem;
}

.comment-buttons {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    justify-content: flex-end;
}

.comment-input {
    color: #1d3f67;
    background-color: #f5f5f5;
    word-break: break-word;
    white-space: pre-line;
    resize: none;
    border-radius: 8px;
    outline: none;
    padding: 1em;
    font-family: "futura-pt";
    width: 100%;
}

.comment-input:focus {
    border-color: #1d3f67;
}

.comment-input::placeholder {
    color: #606266;
}

.comment-owners-header {
    color: #1d3f67;
    width: 100%;
    font-weight: bold;
}

.input-container {
    display: flex;
    flex-direction: column;
    width: 20%;
    padding: 1em;
    border: 1px solid #dcdfe6;
    border-radius: 8px;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.owner-statistics {
    display: flex;
    gap: 1rem;
    width: 100%;
}

.statistic-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1em;
    color: #1d3f67;
    background-color: #f5f5f5;
    border-radius: 8px;
    width: 33%;
}

.handle-owners-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2em 0em;
}

.owner-information-handling-buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.owner-information-handling-buttons > * {
    align-self: center;
}

.kyc-owners-container {
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    align-items: stretch;
}

.kyc-owners-category {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
}

.kyc-owners-category-header {
    font-size: 16px;
    color: #1d3f67;
    font-weight: bold;
}

.statistic-label {
    color: #1d3f67;
    font-weight: bold;
}

.statistic-value {
    color: #1d3f67;
}
</style>
