<template>
    <div style="display: flex; width: 100%; justify-content: flex-end">
        <div class="kyc-modal-footer-container">
            <Card width="100%" title="Riskklass" :use-button="true" button-text="Ändra riskklass" @cardButtonClicked="handleRiskClassDialog">
                <div slot="header" class="clearfix risk-grade-card-header">
                    <span>Riskklass</span>
                    <el-button @click="handleRiskClassDialog" type="primary">Ändra riskklass</el-button>
                </div>
                <div class="risk-factors-container">
                    <div :class="['risk-grade', getCompanyRiskFactorsColors(riskInfo.hasPepOwner)]">
                        PEP
                    </div>
                    <div :class="['risk-grade', getCompanyRiskFactorsColors(riskInfo.hasSanctionedOwner)]">
                        SAN
                    </div>
                    <div :class="['risk-grade', getCompanyRiskFactorsColors(riskInfo.hasRiskySni)]">
                        SNI
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th scope="col">Typ</th>
                            <th scope="col">Aktuell Riskklass</th>
                            <th scope="col" v-if="status !== 'noActionRequired' && !updatedRiskGrade">Ny Riskklass</th>
                            <th scope="col" style="width: 33%" v-else />
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Fastställd risk</td>
                            <td title="Tidigare satt riskklass för klient, har inte en ny riskklass blivit satt så är det den fastställda">
                                <div :class="['risk-grade', getRiskColor(updatedRiskGrade || riskInfo.riskGradeManual)]">{{ updatedRiskGrade || riskInfo.riskGradeManual }}</div>
                            </td>
                            <td />
                        </tr>
                        <tr>
                            <td>Autobedömd risk</td>
                            <td>
                                <div :class="['risk-grade', getRiskColor(riskInfo.oldRiskGradeAuto)]">{{ riskInfo.oldRiskGradeAuto }}</div>
                            </td>
                            <td v-if="status !== 'noActionRequired' && !updatedRiskGrade">
                                <div :class="['risk-grade', getRiskColor(riskInfo.newRiskGradeAuto)]">{{ riskInfo.newRiskGradeAuto }}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Card>
            <div class="kyc-modal-footer-buttons">
                <el-button :disabled="status === 'noActionRequired'" type="success" @click="kycEventExecute('approveClientKyc')">Godkänn</el-button>
                <el-button type="warning" @click="kycEventExecute('updateKycStatus', 'toSupplement')">Begär Komplettering</el-button>
                <el-button type="danger" @click="kycEventExecute('updateKycStatus', 'needExtraCheck')">Krävs Extra Check</el-button>
                <el-button type="danger" @click="kycEventExecute('updateKycStatus', 'denied')">Neka</el-button>
                <el-button type="default" @click="cancelKyc">Avbryt</el-button>
            </div>
        </div>
        <kyc-risk-class-dialog
            :client-name="clientName"
            :client-id="clientId"
            @close="handleRiskClassDialog"
            @riskGradeChanged="handleRiskGradeChanged"
            :risk-class="currentRiskClass"
            :risk-class-dialog-visible.sync="showRiskClassDialog"
        />
    </div>
</template>

<script>
import kycByClientRiskClassDialog from "./kycByClientRiskClassDialog.vue";
import Api from "../kycByClient.api.js";
import Card from "@/components/Card.vue";
export default {
    components: {
        "kyc-risk-class-dialog": kycByClientRiskClassDialog,
        Card,
    },
    props: {
        clientName: {
            type: String,
            default: "",
        },
        riskClass: {
            type: Number,
            default: 0,
        },
        riskInfo: {
            type: Object,
            default: () => ({}),
        },
        status: {
            type: String,
            default: null,
        },
        clientId: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            showRiskClassDialog: false,
            updatedRiskGrade: undefined,
            riskGradeBeforeChange: undefined,
        };
    },
    methods: {
        getRiskColor(riskGrade) {
            switch (riskGrade) {
                case 1:
                    return "risk-grade-green";
                case 2:
                    return "risk-grade-orange";
                case 3:
                    return "risk-grade-red";
                case 4:
                    return "risk-grade-red";
                default:
                    return "risk-grade-grey";
            }
        },
        getCompanyRiskFactorsColors(value) {
            return value === true ? "risk-grade-red" : "risk-grade-green";
        },
        handleRiskClassDialog() {
            this.showRiskClassDialog = !this.showRiskClassDialog;
        },
        handleRiskGradeChanged(newRiskGrade) {
            this.showRiskClassDialog = !this.showRiskClassDialog;
            this.riskGradeBeforeChange = this.currentRiskClass;
            this.updatedRiskGrade = newRiskGrade;
            this.$emit("riskGradeChanged");
        },
        async kycEventExecute(event, status = undefined) {
            try {
                if (status === undefined && event === "approveClientKyc") {
                    await Api[event](this.clientId);
                    this.$notify.success({ title: "Kyc godkänd" });
                } else {
                    await Api[event](this.clientId, status);
                    this.$notify.success({ title: `Kyc uppdaterad` });
                }
            } catch (error) {
                this.$notify.error({ title: `Ett fel uppstod vid kyc uppdatering` });
            }
            this.$emit("kycEventExecute");
        },
        cancelKyc() {
            this.$emit("cancelKyc");
        },
    },
    computed: {
        currentRiskClass() {
            return this.updatedRiskGrade || this.riskInfo.riskGradeManual;
        },
    },
};
</script>

<style scoped>
thead {
    border-bottom: 1px solid #b4c8e0;
}
table {
    width: 100%;
    overflow: hidden;
}

tbody tr {
    border-bottom: 1px solid #b4c8e0;
    transition: background-color 0.2s ease;
}

td,
th {
    font-size: 12px;
    white-space: pre-line;
    text-wrap: nowrap;
    padding-bottom: 1em;
    padding-right: 1em;
}

td {
    padding-top: 1em;
    font-size: 12px;
    color: #1d3f67;
    white-space: pre-line;
}

tr > th {
    font-weight: bold;
    color: #1d3f67;
    font-size: 14px;
}

.kyc-modal-footer-container {
    margin-top: 2.5em;
    display: flex;
    width: fit-content;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1rem;
}
.kyc-modal-footer-buttons {
    padding-top: 1em;
    padding-right: 1em;
    border-top: 1px solid #ebeef5;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 0.5rem;
}

.risk-grade-card-header {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    color: #1d3f67;
    font-size: 18px;
}

.risk-grade {
    border-radius: 3px;
    padding-left: 0.5em;
    padding-right: 0.5em;
    color: #fff;
    font-weight: bold;
    width: fit-content;
    height: fit-content;
    text-align: center;
}
.risk-grade-green {
    background-color: #4caf50;
}
.risk-grade-orange {
    background-color: #ff9800;
}
.risk-grade-red {
    background-color: #f44336;
}
.risk-grade-grey {
    background-color: #9e9e9e;
}

.risk-factors-container {
    display: flex;
    padding-bottom: 2em;
}
.risk-factors-container > div {
    font-size: 12px;
    margin-right: 1em;
}
</style>
