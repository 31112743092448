<template>
    <el-dialog :modal-append-to-body="false" width="30%" :visible.sync="ownersHandlingDialogVisible" v-on:update:visibleSync="visibleSync = $event" :before-close="cancel" append-to-body>
        <span class="owner-management-title" slot="title">Lägg till delägare </span>
        <div class="owner-management-change-container">
            <el-form ref="form" :rules="rules" :model="form" class="owner-management-form" label-position="top">
                <el-form-item prop="personalNumber" style="width: 50%; padding-right: 0.5rem" label="Personnummer">
                    <el-input v-model="form.personalNumber" :minlength="12" :maxlength="12" placeholder="YYYYMMDDXXXX" />
                </el-form-item>
                <el-form-item prop="firstName" class="full-name-container">
                    <el-form-item prop="firstName" class="firstname" label="Förnamn">
                        <el-input v-model="form.firstName" placeholder="Förnamn på delägare" />
                    </el-form-item>
                    <el-form-item prop="lastName" class="lastname" label="Efternamn">
                        <el-input v-model="form.lastName" placeholder="Efternamn på delägare" />
                    </el-form-item>
                </el-form-item>
                <el-form-item prop="email" label="Email">
                    <el-input v-model="form.email" placeholder="Mailadress till delägare" type="email" />
                </el-form-item>
                <el-form-item prop="citizenships" label="Välj Medborgarskap(en)">
                    <el-select style="width: 50%" placeholder="Medborgarskap(en) på delägare" v-model="form.citizenships" multiple>
                        <el-option v-for="country in countries" :label="country.name" :value="country.value" :key="country.value" />
                    </el-select>
                </el-form-item>
                <el-form-item prop="ownerShare" v-if="companyType === 5" style="width: 50%" label="Ägarandel">
                    <el-input v-model="form.ownerShare" placeholder="%" />
                </el-form-item>
                <el-form-item prop="wayOfControl" v-if="companyType === 5" v-show="form.ownerShare > 0" label="Äger/kontrollerar företaget">
                    <el-radio-group v-model="form.wayOfControl">
                        <el-radio :label="1">Privat</el-radio>
                        <el-radio :label="2">Via företag</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="controlByOrgNo" v-if="companyType === 5" v-show="form.wayOfControl === 2" label="Organisationsnummer">
                    <el-input v-model="form.controlByOrgNo" label="Organisationsnummer" />
                </el-form-item>
                <el-form-item prop="isBoardMember" v-if="companyType === 5" label="Medlem i företags styrelse">
                    <el-radio-group v-model="form.isBoardMember">
                        <el-radio :label="false">Nej</el-radio>
                        <el-radio :label="true">Ja</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="boardRole" v-if="companyType === 5" v-show="form.isBoardMember" label="Roll i styrelsen">
                    <el-radio-group v-model="form.boardRole">
                        <el-radio :label="1">Ledamot</el-radio>
                        <el-radio :label="2">Suppleant</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="isPoliticallyConnected" label="Närstående eller känd medarbetare i politiskt utsatt ställning">
                    <el-radio-group v-model="form.isPoliticallyConnected">
                        <el-radio :label="false">Nej</el-radio>
                        <el-radio :label="true">Ja</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="typeOfPoliticalPosition" v-show="form.isPoliticallyConnected" label="Typ av utsatt ställning">
                    <el-select v-model="form.typeOfPoliticalPosition" placeholder="Välj politisk position">
                        <el-option v-for="[value, label] in Object.entries(getEnum.typeOfPoliticalPosition)" :key="value" :label="label" :value="Number(value)" />
                    </el-select>
                </el-form-item>
                <el-form-item prop="politicalRelationship" v-show="form.isPoliticallyConnected" label="Relation till politiskt utsatt person">
                    <el-select v-model="form.politicalRelationship" placeholder="Välj relation">
                        <el-option v-for="[value, label] in Object.entries(getEnum.politicalRelationship)" :key="value" :label="label" :value="Number(value)" />
                    </el-select>
                </el-form-item>
                <el-form-item v-if="(companyType === 5 && form.ownerShare >= 25) || companyType !== 5" style="margin-bottom: 2rem" label="ID-kort / Körkort / Pass">
                    <el-upload :before-upload="beforeUpload" :limit="1" action="#" accept=".pdf,.jpg,.jpeg,.png">
                        <el-button style="margin-top: 0.5rem" type="primary">Ladda upp</el-button>
                    </el-upload>
                    <div class="file-uploaded-container" v-if="file">
                        <div>
                            <i class="fa-solid fa-file-check fa-lg" style="color: #1d3f67;" /> <span style="margin-left: 0.3rem; color: #1d3f67">{{ filename }}</span>
                        </div>
                        <i @click="handleRemove" class="fa-solid fa-trash delete-button" />
                    </div>
                </el-form-item>
            </el-form>
            <div class="owner-management-buttons-container">
                <el-button @click="cancel">Avbryt</el-button>
                <el-button type="primary" @click="setNewOwnerInfo('form')">Lägg till delägare</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import Api from "../kycByClient.api.js";
import Enum from "../../onboarding/onboarding.enum.js";
export default {
    data() {
        return {
            visibleSync: this.ownersHandlingDialogVisible,
            countries: [],
            fileList: [],
            fileType: 0,
            file: "",
            filename: "",
            form: {
                kycTempByIndividualId: 0,
                individualId: 0,
                personalNumber: "",
                firstName: "",
                lastName: "",
                email: "",
                citizenships: [],
                ownerShare: null,
                wayOfControl: -1,
                controlByOrgNo: "",
                isBoardMember: null,
                boardRole: -1,
                principal: false,
                isPoliticallyConnected: null,
                typeOfPoliticalPosition: -1,
                politicalRelationship: 0,
            },
        };
    },
    async created() {
        this.countries = await Api.getCountryList();
    },
    props: {
        ownersHandlingDialogVisible: {
            type: Boolean,
            default: false,
        },
        clientId: {
            type: String,
            default: undefined,
        },
        companyType: {
            type: Number,
            default: undefined,
        },
    },
    methods: {
        getFileType(fileType) {
            switch (fileType) {
                case "application/pdf":
                    return 1;
                case "image/jpeg":
                    return 4;
                case "image/png":
                    return 5;
                default:
                    return 0;
            }
        },
        async beforeUpload(file) {
            this.filename = file.name;
            this.fileType = this.getFileType(file.type);
            this.file = await this.fileToBase64(file);
        },
        handleRemove() {
            this.filename = "";
            this.file = "";
            this.fileType = 0;
        },
        async fileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        setNewOwnerInfo(form) {
            this.$refs[form].validate(valid => {
                if (valid) {
                    let formData = new FormData();
                    formData.append("file", this.file);
                    formData.append("fileType", this.fileType);
                    formData.append("ownerInfoJson", JSON.stringify(this.form));
                    this.addNewOwner(formData);
                } else {
                    return false;
                }
            });
        },
        async addNewOwner(formData) {
            try {
                await Api.saveOwnerInfo(this.clientId, formData);
                this.$notify.success({ title: "Ny delägare skapad" });
                this.$emit("newOwnerAdded");
            } catch (error) {
                this.$notify.error({ title: `Ett fel uppstod vid delägare tilläggning` });
            }
        },
        cancel() {
            this.$emit("close");
            this.form = {
                kycTempByIndividualId: 0,
                individualId: 0,
                personalNumber: "",
                firstName: "",
                lastName: "",
                email: "",
                citizenships: [],
                ownerShare: null,
                wayOfControl: -1,
                controlByOrgNo: "",
                isBoardMember: null,
                boardRole: -1,
                principal: false,
                isPoliticallyConnected: null,
                typeOfPoliticalPosition: -1,
                politicalRelationship: 0,
            };
            this.fileList = [];
            this.file = "";
            this.fileType = 0;
            this.$refs.form.clearValidate();
        },
    },
    computed: {
        getEnum() {
            return Enum;
        },
        rules() {
            return {
                personalNumber: [
                    { required: true, message: "Personnummer är obligatoriskt", trigger: "blur" },
                    { min: 12, max: 12, message: "Personnummer måste vara 12 siffror", trigger: "blur" },
                    { pattern: /^[0-9]+$/, message: "Personnummer får endast innehålla siffror", trigger: "blur" },
                ],
                firstName: [{ required: true, message: "Förnamn är obligatoriskt", trigger: "blur" }],
                lastName: [{ required: true, message: "Efternamn är obligatoriskt", trigger: "blur" }],
                email: [
                    { required: true, message: "Email är obligatoriskt", trigger: "blur" },
                    { type: "email", message: "Ogiltig email", trigger: "blur" },
                ],
                citizenships: [{ required: true, message: "Medborgarskap är obligatoriskt", trigger: "blur" }],
                ownerShare: [{ required: this.companyType === 5, message: "Ägarandel är obligatoriskt", trigger: "blur" }],
                wayOfControl: [{ required: this.companyType === 5, message: "Välj hur företaget ägs/kontrolleras", trigger: "blur" }],
                controlByOrgNo: [{ required: this.companyType === 5 && this.form.wayOfControl === 2, message: "Organisationsnummer är obligatoriskt", trigger: "blur" }],
                isBoardMember: [{ required: this.companyType === 5, message: "Välj om personen är medlem i företagets styrelse", trigger: "blur" }],
                boardRole: [{ required: this.companyType === 5 && this.form.isBoardMember, message: "Välj roll i styrelsen", trigger: "blur" }],
                isPoliticallyConnected: [{ required: true, message: "Välj om personen är närstående eller känd medarbetare i politiskt utsatt ställning", trigger: "blur" }],
                typeOfPoliticalPosition: [{ required: this.form.isPoliticallyConnected, message: "Välj typ av utsatt ställning", trigger: "blur" }],
                politicalRelationship: [{ required: this.form.isPoliticallyConnected, message: "Välj relation till politiskt utsatt person", trigger: "blur" }],
            };
        },
    },
    // Other component options (e.g., computed properties, lifecycle hooks) can be added here
};
</script>

<style scoped>
.file-uploaded-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
    justify-content: space-between;
    padding: 0.2rem; /* Optional: Add padding for a nicer hover effect */
    transition: background-color 0.3s ease; /* Smooth transition */
}

.file-uploaded-container:hover {
    background-color: #f0f0f0;
}

.file-uploaded-container:hover .delete-button {
    display: inline-block; /* Show delete button on hover */
}

.delete-button {
    cursor: pointer;
    color: #1d3f67;
    display: none;
    margin-right: 0.5rem;
}
.owner-management-title {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #1d3f67 !important;
}
.firstname {
    width: 100%;
    margin-right: 1rem;
}
.lastname {
    width: 100%;
}
.el-form-item {
    margin-bottom: 0 !important;
}
.full-name-container >>> .el-form-item__content {
    display: flex;
    flex: 1 1;
    width: 100%;
}
.citizenships-container {
    display: flex;
    flex-direction: column;
}
.owner-management-change-title {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #617d7f !important;
}
.info-text {
    color: #617d7f;
    font-size: 14px;
}
.owner-management-change-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.owner-management-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.owner-management-buttons-container {
    display: flex;
    width: 100%;
    margin-top: 2rem;
    justify-content: flex-end;
}
</style>
