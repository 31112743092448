<template>
    <el-dialog :fullscreen="true" style="padding: 3em 2em; margin-left: 5rem; height: 100%;" :visible="dialogVisible" :before-close="handleClose" v-on:update:visibleSync="visibleSync = $event">
        <span class="kyc-dialog-title" slot="title">Känn din kund - </span>
        <span class="kyc-dialog-title" slot="title">{{ clientName }}</span>
        <div v-loading="loading">
            <div v-if="clientId && kycClientData">
                <kyc-company-info-header
                    ref="kycCompanyInfoHeader"
                    v-if="!loading && comparisonDataReady"
                    :status="statusEnumName"
                    :client-id="clientId"
                    :general-info="generalInfo"
                    :company-info="comparisonData"
                />
                <kyc-company-info-comparison-main v-if="!loading && comparisonDataReady" :status="statusEnumName" :answers="comparisonData" />
                <kyc-company-info-sni-codes :sni-codes="sniCodesData" />
                <kyc-company-info-owners :status="statusEnumName" :client-id="clientId" @commentAdded="updateEventsList" @ownerAdded="updateEventsList" />
                <kyc-handle-company-info-footer
                    :client-name="clientName"
                    :risk-info="riskData"
                    @cancelKyc="handleClose"
                    :client-id="clientId"
                    @kycEventExecute="onKycEventExecute"
                    @riskGradeChanged="updateEventsList"
                    :risk-class="kycClientData.riskGradeManual"
                    :status="statusEnumName"
                />
            </div>
        </div>
    </el-dialog>
</template>

<script>
import Api from "../kycByClient.api.js";
import kycByClientNewHeaderInfo from "./kycByClientNewHeaderInfo.vue";
import kycByClientNewMainContentComparison from "./kycByClientNewMainContentComparison.vue";
import kycByClientNewSniCodes from "./kycByClientNewSniCodes.vue";
import kycByClientNewOwners from "./kycByClientNewOwners.vue";
import kycByClientNewFooter from "./kycByClientNewFooter.vue";
export default {
    components: {
        "kyc-company-info-header": kycByClientNewHeaderInfo,
        "kyc-company-info-comparison-main": kycByClientNewMainContentComparison,
        "kyc-company-info-sni-codes": kycByClientNewSniCodes,
        "kyc-company-info-owners": kycByClientNewOwners,
        "kyc-handle-company-info-footer": kycByClientNewFooter,
    },
    data() {
        return {
            visibleSync: this.dialogVisible,
            kycClientData: null,
            loading: false,
        };
    },
    props: {
        dialogVisible: {
            type: Boolean,
            default: false,
        },
        clientId: {
            type: String,
            default: undefined,
        },
        clientName: {
            type: String,
            default: "",
        },
        displayedStatusName: {
            type: String,
            default: undefined,
        },
        statusEnumName: {
            type: String,
            default: undefined,
        },
    },
    watch: {
        visibleSync(val) {
            this.$emit("update:dialogVisible", val);
        },
        clientId: {
            immediate: true,
            async handler(newClientId) {
                if (newClientId) {
                    try {
                        this.loading = true;
                        this.kycClientData = await Api.getClientKycData(newClientId);
                    } catch (error) {
                        console.error(error);
                    } finally {
                        this.loading = false;
                    }
                }
            },
        },
    },
    computed: {
        sniCodesData() {
            if (!this.kycClientData) return {};
            else {
                return {
                    current: this.kycClientData.currentSniCodes || [],
                    syna: this.kycClientData.synaSniCodes || [],
                };
            }
        },
        riskData() {
            if (!this.kycClientData) return {};
            else {
                return this.kycClientData.riskClass;
            }
        },
        comparisonDataReady() {
            return !!(this.kycClientData?.oldCompanyInfo && this.kycClientData?.actualCompanyInfo);
        },
        comparisonData() {
            const { oldCompanyInfo, newCompanyInfo, actualCompanyInfo, currentTaxStatus, synaTaxStatus } = this.kycClientData || {};
            return {
                oldCompanyInfo,
                newCompanyInfo: newCompanyInfo || [],
                actualCompanyInfo,
                currentTaxStatus: currentTaxStatus || {},
                synaTaxStatus: synaTaxStatus || {},
            };
        },
        generalInfo() {
            if (!this.kycClientData) return {};
            else {
                return {
                    status: this.displayedStatusName || "-",
                    submittedTime: this.kycClientData.submittedTime,
                };
            }
        },
    },
    methods: {
        async updateEventsList() {
            this.$refs.kycCompanyInfoHeader.getEvents();
        },
        onKycEventExecute() {
            this.$emit("eventExecuted");
        },
        handleClose(done) {
            this.kycClientData = null;
            this.$emit("close");
            done();
        },
    },
};
</script>

<style scoped>
div >>> .el-dialog {
    background-color: #fafafa;
}

.kyc-dialog-title {
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #1d3f67 !important;
}
</style>
