<template>
    <el-card class="card-component" :style="{ height: height, maxHeight: maxHeight, width: width, maxWidth: maxWidth }">
        <div slot="header" class="clearfix card-component-header">
            <span>{{ title }}</span>
            <el-button v-if="useButton" @click="cardButtonClicked" :type="buttonType">{{ buttonText }}</el-button>
        </div>
        <div v-loading="loading" />
        <slot v-if="!loading" />
    </el-card>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
            default: "",
        },
        width: {
            type: String,
            default: "",
            required: false,
        },
        maxWidth: {
            type: String,
            default: "",
            required: false,
        },
        height: {
            type: String,
            default: "",
            required: false,
        },
        maxHeight: {
            type: String,
            default: "",
            required: false,
        },
        useButton: {
            type: Boolean,
            default: false,
            required: false,
        },
        buttonType: {
            type: String,
            default: "primary",
            required: false,
        },
        buttonText: {
            type: String,
            default: "Button",
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    methods: {
        cardButtonClicked() {
            this.$emit("cardButtonClicked");
        },
    },
};
</script>

<style scoped>
.card-component {
    border-radius: 18px;
    border: none;
}
div >>> .el-card__body {
    padding: 0 20px 3em 20px !important;
    height: 100%;
}
div >>> .el-card__header {
    padding-bottom: 2em !important;
    border-bottom: none !important;
}

.card-component-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    color: #1d3f67;
    font-size: 18px;
    word-break: break-word;
}
</style>
