<template>
    <Card :title="title" width="100%">
        <table>
            <thead>
                <tr>
                    <th>SNI-Kod</th>
                    <th>Beskrivning</th>
                    <th>Kontantintensiv</th>
                    <th>Riskbranch</th>
                    <th>Risknivå</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(sni, index) in sniCodes" :key="index">
                    <td>{{ sni.sniCode }}</td>
                    <td>{{ sni.description }}</td>
                    <td>{{ sni.cashIntensive ? "Ja" : "Nej" }}</td>
                    <td>{{ sni.riskIndustry ? "Ja" : "Nej" }}</td>
                    <td>
                        <div :class="['risk-grade', getRiskColor(sni.risk)]">{{ sni.risk }}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </Card>
</template>

<script>
import Card from "@/components/Card.vue";
export default {
    name: "SniCard",
    props: {
        title: {
            type: String,
            required: true,
        },
        sniCodes: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        Card,
    },
    methods: {
        getRiskColor(riskGrade) {
            switch (riskGrade) {
                case 1:
                    return "risk-grade-green";
                case 2:
                    return "risk-grade-orange";
                case 3:
                    return "risk-grade-red";
                case 4:
                    return "risk-grade-red";
                default:
                    return "risk-grade-grey";
            }
        },
    },
};
</script>

<style scoped>
thead {
    border-bottom: 1px solid #b4c8e0;
}
table {
    width: 100%;
    overflow: hidden;
}

tbody tr {
    border-bottom: 1px solid #b4c8e0;
    transition: background-color 0.2s ease;
}

td,
th {
    font-size: 12px;
    white-space: pre-line;
    text-wrap: nowrap;
    padding-bottom: 1em;
    padding-right: 1em;
}

td {
    padding-top: 1em;
    font-size: 12px;
    color: #1d3f67;
    white-space: pre-line;
}

tr > th {
    font-weight: bold;
    color: #1d3f67;
    font-size: 14px;
}

.risk-grade {
    border-radius: 3px;
    padding-left: 0.5em;
    padding-right: 0.5em;
    color: #fff;
    font-weight: bold;
    width: fit-content;
    height: fit-content;
    text-align: center;
}
.risk-grade-green {
    background-color: #4caf50;
}
.risk-grade-orange {
    background-color: #ff9800;
}
.risk-grade-red {
    background-color: #f44336;
}
.risk-grade-grey {
    background-color: #9e9e9e;
}
</style>
