const Enum = {
    kycByClientStatus: {
        0: "Utkast",
        1: "Inskickad",
        2: "Godkänd",
        3: "Ingen åtgärd krävs",
        4: "Ej påbörjad",
    },
    PageStatus: {
        Utkast: 0,
        Inskickad: 1,
        Godkänd: 2,
        IngenAtgärdKrävs: 3,
        EjPåbörjad: 4,
    },
    kycByClientStatusNew: {
        0: "sentToClient",
        1: "inProgress",
        2: "submitted",
        3: "noActionRequired",
        4: "notSent",
        5: "toSupplement",
        6: "supplemented",
        7: "needExtraCheck",
        8: "denied",
        9: "blocked",
    },
    kycByClientNewCorporateTax: {
        0: "Saknas F-Skatt information",
        1: "F-Skatt Registerad",
        2: "F-Skatt Ej registerad",
    },
    kycByClientNewVatStatus: {
        0: "Saknas Moms information",
        1: "Moms Registerad",
        2: "Moms Ej registerad",
    },
    kycByClientNewEmployerStatus: {
        0: "Saknas arbetsgivarinformation",
        1: "Registerad arbetsgivare",
        2: "Ej registerad arbetsgivare",
    },
    kycByClientNewTurnOver: {
        1: "0 - 500 000",
        2: "500 000 - 1 000 000",
        3: "1 000 000 - 3 000 000",
        4: "3 000 000 - 5 000 000",
        5: "5 000 000 - 20 000 000",
        6: "Över 20 000 000",
    },
};
export default Enum;
