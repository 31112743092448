<template>
    <div v-if="companyInfo">
        <header>
            <div class="main-container-column">
                <Card title="Status" width="40%">
                    <div class="align-column">
                        <div class="info-item">
                            <span class="info-label">Status: </span>
                            <span class="info-value">{{ generalInfo.status }}</span>
                        </div>
                        <div class="info-item">
                            <span class="info-label">Inskickat av klient:</span>
                            <span class="info-value">{{ formatDate(generalInfo.submittedTime) }}</span>
                        </div>
                    </div>
                </Card>
                <Card title="Allmän Företagsinformation" :width="status === 'noActionRequired' ? '75%' : '100%'">
                    <table>
                        <thead>
                            <tr>
                                <th>Aktuell</th>
                                <th v-if="status !== 'noActionRequired'">Ny</th>
                                <th>Syna</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="display-block-group">
                                    <span>{{ companyInfoComparison.current.address }}</span>
                                    <span>{{ companyInfoComparison.current.address2 }}</span>
                                    <span>{{ companyInfoComparison.current.city }}</span>
                                </td>
                                <td class="display-block-group" v-if="status !== 'noActionRequired'">
                                    <template v-if="companyInfo.newCompanyInfo">
                                        <span>{{ companyInfoComparison.new.address }}</span>
                                        <span>{{ companyInfoComparison.new.address2 }}</span>
                                        <span>{{ companyInfoComparison.new.city }}</span>
                                    </template>
                                </td>
                                <td class="display-block-group">
                                    <span>{{ companyInfoComparison.syna.address }}</span>
                                    <span>{{ companyInfoComparison.syna.address2 }}</span>
                                    <span>{{ companyInfoComparison.syna.city }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ companyInfoComparison.current.corporateTax }}</td>
                                <td v-if="status !== 'noActionRequired'" />
                                <td>{{ companyInfoComparison.syna.corporateTax }}</td>
                            </tr>
                            <tr>
                                <td>{{ companyInfoComparison.current.vatStatus }}</td>
                                <td v-if="status !== 'noActionRequired'" />
                                <td>{{ companyInfoComparison.syna.vatStatus }}</td>
                            </tr>
                            <tr>
                                <td>{{ companyInfoComparison.current.employerStatus }}</td>
                                <td v-if="status !== 'noActionRequired'" />
                                <td>{{ companyInfoComparison.syna.employerStatus }}</td>
                            </tr>
                        </tbody>
                    </table>
                </Card>
            </div>
            <div class="main-container-column-right">
                <Card :loading="loadingEvents" title="Händelser Test" width="60%" max-height="100%">
                    <EventsList :events="events" :icon-mappings="eventIconMappings" @view-details="handleViewDetails" />
                    <event-details-dialog
                        :event-details-dialog-visible.sync="showEventDetailsDialog"
                        :event-details="eventDetails"
                        :title="eventDetailsTitle"
                        @close="handleEventDetailsDialog"
                        :event-details-loading="loadingEventDetails"
                    />
                </Card>
            </div>
        </header>
    </div>
</template>

<script>
import moment from "moment";
import EventsList from "@/components/events/EventList.vue";
import Card from "@/components/Card.vue";
import LogApi from "../../loggar/loggar.api.js";
import kycEventDetailsDialog from "./kycEventDetailsDialog.vue";
export default {
    props: {
        companyInfo: {
            type: Object,
            default: () => ({}),
        },
        generalInfo: {
            type: Object,
            default: () => ({}),
        },
        clientId: {
            type: String,
            default: null,
        },
        status: {
            type: String,
            default: "",
        },
    },
    components: {
        EventsList,
        Card,
        "event-details-dialog": kycEventDetailsDialog,
    },
    data() {
        return {
            events: [],
            showEventDetailsDialog: false,
            loadingEventDetails: false,
            eventDetails: null,
            eventDetailsTitle: "",
            recentEventAuditLogId: null,
            eventIconMappings: {
                risk: { icon: "fa-solid fa-user-cog fa-lg", color: "#FF7043" },
                skapade: { icon: "fa-solid el-icon-plus fa-lg", color: "#66BB6A" },
                inskickat: { icon: "fa-solid fa-paper-plane fa-lg", color: "#42A5F5" },
                skickat: { icon: "fa-solid fa-share", color: "#1d3f67" },
                approved: { icon: "fa-solid fa-file-check fa-lg", color: "#28a745" },
                overwrite: { icon: "fa-solid fa-exchange fa-lg", color: "#FFC107" },
                identitet: { icon: "fa-solid fa-id-card fa-lg", color: "#007bff" },
                kommentar: { icon: "fa-solid fa-comment-dots fa-lg", color: "#1d3f67" },
                default: { icon: "fas fa-question fa-lg", color: "#1d3f67" },
            },
        };
    },
    created() {
        if (this.clientId) {
            this.getEvents();
        }
    },
    computed: {
        companyInfoComparison() {
            return {
                current: {
                    address: this.companyInfo.oldCompanyInfo.address,
                    address2: this.companyInfo.oldCompanyInfo.address2,
                    city: this.companyInfo.oldCompanyInfo.postalCode + " " + this.companyInfo.oldCompanyInfo.city,
                    corporateTax: this.companyInfo.currentTaxStatus.corporateTax,
                    vatStatus: this.companyInfo.currentTaxStatus.vatStatus,
                    employerStatus: this.companyInfo.currentTaxStatus.employerStatus,
                },
                new: {
                    address: this.companyInfo.newCompanyInfo.address,
                    address2: this.companyInfo.newCompanyInfo.address2,
                    city: this.companyInfo.newCompanyInfo.postalCode + " " + this.companyInfo.newCompanyInfo.city,
                    corporateTax: this.companyInfo.synaTaxStatus.corporateTax,
                    vatStatus: this.companyInfo.synaTaxStatus.vatStatus,
                    employerStatus: this.companyInfo.synaTaxStatus.employerStatus,
                },
                syna: {
                    address: this.companyInfo.actualCompanyInfo.address,
                    address2: this.companyInfo.actualCompanyInfo.address2,
                    city: (this.companyInfo.actualCompanyInfo.postalCode ?? "") + " " + (this.companyInfo.actualCompanyInfo.city ?? ""),
                    corporateTax: this.companyInfo.synaTaxStatus.corporateTax,
                    vatStatus: this.companyInfo.synaTaxStatus.vatStatus,
                    employerStatus: this.companyInfo.synaTaxStatus.employerStatus,
                },
            };
        },
        groupedEvents() {
            return this.groupByDate(this.events);
        },
    },
    methods: {
        async handleViewDetails(eventClickedDetails) {
            if (this.recentEventAuditLogId !== eventClickedDetails.id) {
                // Fetch event details if not already fetched
                this.loadingEventDetails = true;
                this.handleEventDetailsDialog();
                this.recentEventAuditLogId = eventClickedDetails.id;
                let response = await LogApi.getAuditLog(eventClickedDetails.id);
                this.eventDetails = response.data;
                this.eventDetailsTitle = eventClickedDetails.eventTitle;
            } else {
                // Toggle dialog if already fetched
                this.handleEventDetailsDialog();
            }
            if (this.eventDetails) {
                this.loadingEventDetails = false;
            }
        },
        handleEventDetailsDialog() {
            this.showEventDetailsDialog = !this.showEventDetailsDialog;
        },
        async getEvents() {
            try {
                this.loadingEvents = true;
                const groupType = [14]; // only kyc events
                const sourceTypes = [1, 2]; // elsa, app
                const response = await LogApi.getAuditLogs("", "", 1, 100, "", groupType, sourceTypes, this.clientId);
                this.events = response.data;
                this.loadingEvents = false;
            } catch (error) {
                console.error(error);
            }
        },
        formatDate(unformattedDate) {
            const date = unformattedDate;
            if (date === "0001-01-01T00:00:00") {
                return "Ej inskickat";
            }
            return moment(date).format("YYYY-MM-DD");
        },
        formatTime(unformattedDate) {
            return moment(unformattedDate).format("HH:mm");
        },
        formatDateHeader(date) {
            return moment(date)
                .locale("en")
                .format("MMM D, YYYY");
        },
        groupByDate(events) {
            return events.reduce((acc, event) => {
                const date = moment(event.createdAt).format("YYYY-MM-DD"); // Format the date
                if (!acc[date]) {
                    acc[date] = [];
                }
                acc[date].push(event);
                return acc;
            }, {});
        },
    },
};
</script>

<style scoped>
thead {
    border-bottom: 1px solid #b4c8e0;
}
table {
    width: 100%;
    overflow: hidden;
}

tbody tr {
    border-bottom: 1px solid #b4c8e0;
    transition: background-color 0.2s ease;
}

td,
th {
    white-space: pre;
    padding-bottom: 1em;
    padding-right: 2em;
    min-width: 33%;
}

td {
    padding-top: 1em;
    font-size: 12px;
    color: #1d3f67;
}

tr > th {
    font-weight: bold;
    color: #1d3f67;
    font-size: 14px;
}

.display-block-group > * {
    display: block;
}

.main-container-column-right {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    min-width: 60%;
    align-items: flex-end;
    height: 100%;
}

.main-container-column {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    min-width: 40%;
    height: 100%;
}
header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 65vh;
}

.align-column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.info-item {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    width: 100%;
    font-size: 12px;
}
.info-label {
    color: #1d3f67;
    font-weight: bold;
}

.info-value {
    color: #1d3f67;
    font-size: 12px;
}
</style>
