<template>
    <div v-if="events && events.length > 0" class="events-list-container">
        <div v-for="(eventDateGroup, date) in eventsByDate" :key="date">
            <h3 class="events-date-header"><i class="fas fa-calendar-alt" /> {{ formatDateHeader(date) }}</h3>
            <div v-for="(event, index) in eventDateGroup" :key="index" class="event-column-group">
                <div class="event-row">
                    <div class="event-content-container">
                        <div class="event-details-wrapper">
                            <EventTimeline :event-time="formatTime(event.createdAt)" />
                            <EventItem
                                @viewDetails="showEventDetails"
                                :event="event"
                                :icon-name="getEventIcon(event.text)"
                                :icon-color="getEventColor(event.text)"
                                :event-text="event.text"
                                :event-user="formattedUserName(event)"
                                :group-events="groupEvents"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <h3 class="events-date-header"><i class="fas fa-home" />Start på händelser</h3>
    </div>
    <div v-else class="events-list-container">
        <h3 class="events-date-header">Inga händelser att visa</h3>
    </div>
</template>

<script>
import moment from "moment";
import EventItem from "./EventItem.vue";
import EventTimeline from "./EventTimeline.vue";
export default {
    props: {
        events: {
            type: Array,
            default: () => [],
        },
        iconMappings: {
            type: Object,
            default: () => ({
                // Default icon and color mappings
                default: {
                    icon: "fa-solid fa-circle fa-lg",
                    color: "#1d3f67",
                },
            }),
        },
    },
    components: {
        EventItem,
        EventTimeline,
    },
    data() {
        return {
            groupEvents: [
                {
                    eventText: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                    eventUser: "Jane Doe",
                },
                {
                    eventText: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                    eventUser: "John Doe",
                },
            ],
        };
    },
    computed: {
        eventsByDate() {
            return this.groupByDate(this.events);
        },
    },
    methods: {
        formattedUserName(event) {
            return event.adminUserName === "System" ? event.clientUserName : event.adminUserName;
        },
        getEventIcon(eventText) {
            const lowerCaseEventText = eventText.toLowerCase();
            const mapping = Object.entries(this.iconMappings).find(([key]) => lowerCaseEventText.includes(key.toLowerCase()));
            return mapping ? mapping[1].icon : this.iconMappings.default.icon;
        },
        getEventColor(eventText) {
            const lowerCaseEventText = eventText.toLowerCase();
            const mapping = Object.entries(this.iconMappings).find(([key]) => lowerCaseEventText.includes(key.toLowerCase()));
            return mapping ? mapping[1].color : this.iconMappings.default.color;
        },
        groupByDate(events) {
            return events.reduce((acc, event) => {
                const date = moment(event.createdAt).format("YYYY-MM-DD");
                if (!acc[date]) acc[date] = [];
                acc[date].push(event);
                return acc;
            }, {});
        },
        formatDateHeader(date) {
            return moment(date)
                .locale("en")
                .format("MMM D, YYYY");
        },
        formatTime(unformattedDate) {
            return moment(unformattedDate).format("HH:mm");
        },
        showEventDetails(eventDetails) {
            this.$emit("view-details", eventDetails);
        },
    },
};
</script>

<style scoped>
.events-list-container {
    padding-right: 2em;
    height: 90%;
    width: 100%;
    overflow-y: auto;
}
.events-date-header {
    color: #1d3f67;
    font-size: 14px;
    display: flex;
    gap: 0.3rem;
    align-items: center;
    padding-right: 0.75em;
    padding-bottom: 0.2em;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    font-weight: bold;
    word-break: break-all;
    white-space: pre;
}

.event-column-group {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.event-row {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    font-size: 12px;
}

.event-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.event-details-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}
</style>
