<template>
    <div class="event-item-container">
        <div class="event-details-container" :class="{ 'group-events-open': showingGroupEvents }">
            <div @click="viewDetails(event)" class="event-box">
                <div class="event-box-content">
                    <i :class="[iconName, 'event-icon']" :style="{ backgroundColor: iconColor }" />
                    <div class="event-description-container">
                        <span v-if="eventDate" class="event-date">{{ eventDate }}</span>
                        <span class="event-title">{{ eventText }}</span>
                        <span v-if="eventUser" class="event-user-label">
                            {{ eventUser }}
                        </span>
                    </div>
                </div>
            </div>
            <i v-if="groupEvents.length" @click="handleGroupEvents" class="fa-solid fa-chevron-down show-event-details dropdown-arrow" :class="{ 'rotated-arrow': showingGroupEvents }" />
        </div>
        <transition v-if="groupEvents.length" name="slide">
            <div v-show="showingGroupEvents" class="group-events">
                <div class="group-event-details-container">
                    <div v-for="groupEvent in groupEvents" :key="groupEvent.text" class="event-box">
                        <i class="fa fa-tasks fa-lg event-icon-group-event" :style="{ backgroundColor: iconColor, filter: 'brightness(1.2)' }" />
                        <div class="group-event-description-container">
                            <span class="event-title">{{ groupEvent.eventText }}</span>
                            <span class="event-user-label">{{ groupEvent.eventUser }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        event: {
            type: Object,
            required: true,
        },
        eventText: {
            type: String,
            required: false,
        },
        iconName: {
            type: String,
            required: false,
        },
        iconColor: {
            type: String,
            required: false,
        },
        eventUser: {
            type: String,
            required: false,
        },
        eventDate: {
            type: String,
            required: false,
        },
        groupEvents: {
            type: Array,
            required: false,
        },
    },
    data() {
        return {
            showingGroupEvents: false,
        };
    },
    methods: {
        viewDetails(event) {
            this.$emit("viewDetails", { id: event.auditLogId, eventTitle: event.text });
        },
        handleGroupEvents() {
            this.showingGroupEvents = !this.showingGroupEvents;
        },
    },
};
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.slide-enter,
.slide-leave-to {
    max-height: 0;
    padding: 0;
}

.slide-enter-to,
.slide-leave {
    max-height: 500px;
    padding: 0;
}
.group-events {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.group-event-details-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: fit-content;
}
.event-details-container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.event-details-container.group-events-open {
    background-color: #f5f5f5;
    border-radius: 5px;
    .show-event-details {
        display: inline-block;
        color: #808080;
    }
}
.event-details-container:hover {
    background-color: #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
    .show-event-details {
        display: inline-block;
        color: #808080;
    }
}
.event-item-container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-left: 0.5rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}

.event-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 1em;
    width: 100%;
}
.event-box:hover {
    cursor: pointer;
    .show-event-details {
        display: inline-block;
        color: #808080;
    }
}

.group-events .event-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 1em 0.5em 1em;
    width: 100%;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.group-events .event-box:hover {
    cursor: pointer;
    background-color: #f5f5f5;
    border-radius: 5px;
}
.show-event-details {
    display: none;
    position: absolute;
    right: 1.5em;
    display: none;
    color: #1d3f67;
    cursor: pointer;
    padding: 0.5em;
}
.dropdown-arrow {
    transition: transform 0.2s;
    padding: 0.5em;
}

.rotated-arrow {
    padding: 0.5em;
    transform: rotate(180deg);
}

.event-box-content {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
}
.event-icon {
    border-radius: 50%;
    color: white;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
}
.event-icon-group-event {
    border-radius: 50%;
    color: white;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
}
.event-description-container {
    display: flex;
    flex-direction: column;
    padding-left: 1em;
    width: 100%;
}
.group-event-description-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-right: 0.75rem;
}
.event-title {
    color: #1d3f67;
    font-weight: bold;
    font-size: 12px;
    word-break: break-word;
    display: flex;
    gap: 1rem;
    align-items: center;
}
.event-user-label {
    color: #1d3f67;
    font-size: 12px;
    font-weight: normal;
}
.event-date {
    width: 95%;
    color: #9ba5b1;
    font-size: 12px;
    font-weight: normal;
    white-space: nowrap;
}
</style>
