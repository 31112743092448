<template>
    <div v-if="owner" class="kyc-owner-card">
        <template v-if="owner.isPlaceholder">
            <div />
        </template>
        <template v-else>
            <div class="user-icon">
                <i v-if="owner.hasIdentificationCard" :class="setIconBasedOnPersonalNumber(owner.personalNumber)" />
                <el-button v-if="owner.hasIdentificationCard" class="show-id-button" @click="showId(owner)" type="primary">Visa ID</el-button>
            </div>
            <div class="owner-information-list">
                <span class="value-name-personal-number">{{ owner.name }} - {{ owner.personalNumber }}</span>
                <span class="value">{{ owner.ownerShareDescription }}</span>
                <template v-if="ownerCardType === 'third-party'">
                    <span v-for="(description, index) in owner.controlDescriptions" :key="index" class="value">{{ description }}</span>
                </template>
                <span v-if="ownerCardType !== 'third-party'" class="value">{{ owner.boardRoleDescription }}</span>
                <span class="political-value">
                    {{ owner.politicalConnectionDescription }}
                    <span class="cm1-warning-container" v-if="owner.cm1PEP">CM1: PEP <i class="fas fa-exclamation-triangle" style="color: #ff8383;"/></span>
                    <span v-else>
                        CM1: (Ej PEP)
                    </span>
                </span>
                <span class="value">{{ owner.citizenships }}</span>
                <span v-if="ownerCardType !== 'third-party'" class="value">{{ owner.email }}</span>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        owner: {
            type: Object,
            required: true,
        },
        ownerCardType: {
            type: String,
        },
        clientId: {
            type: String,
        },
    },
    methods: {
        async showId(owner) {
            var token = this.$auth.getToken();
            var headers = "token=" + token;
            var base = this.axios.defaults.baseURL;
            let ownerTypeId = this.ownerCardType === "new" ? owner.kycTempByClientIndividualId : owner.individualId;
            let isDraft = this.ownerCardType === "new";
            var url = `/kycByClient/${this.clientId}/owners/${ownerTypeId}/identificationCard?isDraft=${isDraft}&`;
            window.open(base + url + headers, `_blank`, `width=800, height=800`);
        },
        setIconBasedOnPersonalNumber(personalNumber) {
            const genderNumber = personalNumber.slice(-2, -1);
            return genderNumber % 2 === 0 ? "fa-solid fa-user-hair-long fa-4x" : "fa-solid fa-user fa-4x";
        },
    },
};
</script>

<style scoped>
.show-id-button {
    position: absolute;
    top: 4.5rem;
}
.kyc-owner-card {
    flex-grow: 1;
    min-height: 280px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
    background-color: #fafafa;
    border-radius: 18px;
    padding: 0.5em;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.value-name-personal-number {
    font-weight: bold;
    font-size: 14px;
}

.value {
    font-size: 12px;
    border-bottom: 1px solid #b4c8e0;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    white-space: pre-line;
    word-break: break-word;
}

.user-icon {
    display: flex;
    justify-content: center;
    margin-top: 8rem;
    margin-left: 1em;
    height: 100%;
    width: 30%;
    position: relative;
}

.user-icon i {
    color: #1d3f67;
}

.political-value {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    border-bottom: 1px solid #b4c8e0;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    white-space: pre-line;
    word-break: break-word;
}

.cm1-warning-container {
    border-radius: 5px;
    border: 2px dashed #ff8383;
    padding-right: 0.5em;
    padding-left: 0.5em;
    color: #ff8383;
    font-weight: bold;
    font-size: 14px;
}

.owner-information-list {
    color: #1d3f67;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-right: 2em;
    padding-left: 1em;
    gap: 0.5rem;
    overflow-y: auto;
}
.owner-information-content {
    display: flex;
    gap: 1rem;
    align-items: center;
}
</style>
