<template>
    <div>
        <el-dialog :visible.sync="eventDetailsDialogVisible" v-on:update:visibleSync="visibleSync = $event" :before-close="cancel" append-to-body>
            <span v-if="!eventDetailsLoading" class="event-details-dialog-title" slot="title">{{ title }}</span>
            <div v-if="eventDetails" v-loading="eventDetailsLoading" class="event-details-container">
                <Card v-if="!eventDetailsLoading" title="Händelseinformation" width="45%">
                    <div class="event-detail">
                        <span class="event-detail-label">Utfört:</span>
                        <span class="event-detail-value">{{ eventDetails.createdAt }}</span>
                    </div>
                    <div class="event-detail">
                        <span class="event-detail-label">Utfört av:</span>
                        <span class="event-detail-value">{{ eventResponsibleType }}</span>
                    </div>
                    <div class="event-detail">
                        <span class="event-detail-label">Ansvarig:</span>
                        <span class="event-detail-value">
                            {{ eventResponsibleType === "Admin" ? eventDetails.adminUserName : eventDetails.clientUserName }}
                        </span>
                    </div>
                    <div class="event-detail">
                        <span class="event-detail-label">System:</span>
                        <span class="event-detail-value">{{ eventDetails.systemSource }}</span>
                    </div>
                </Card>
                <Card v-if="!eventDetailsLoading" title="Händelsedetaljer" :width="hasUpdatedValues ? '100%' : '45%'">
                    <div v-if="hasChanges">
                        <table v-if="hasUpdatedValues" class="event-details-table">
                            <thead>
                                <tr>
                                    <th>Fält</th>
                                    <th>Före</th>
                                    <th>Efter</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(oldValue, key) in formatValues(eventDetails.oldValues)" :key="key">
                                    <td>
                                        <span class="event-detail-label">{{ key }}</span>
                                    </td>
                                    <td>
                                        <span class="event-detail-value">{{ oldValue }}</span>
                                    </td>
                                    <td>
                                        <span class="event-detail-value">{{ formatValues(eventDetails.newValues)[key] }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-else>
                            <div>
                                <div class="event-details-comparison-updated">
                                    <div v-for="(newValue, key) in formatValues(eventDetails.newValues)" :key="key">
                                        <div class="event-detail">
                                            <span class="event-detail-label">{{ key }}</span>
                                            <span class="event-detail-value">{{ newValue }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="event-details-no-change" v-else>Sparat utan ändringar/Inga ändringar gjorda</div>
                </Card>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Card from "@/components/Card.vue";
export default {
    data() {
        return {
            visibleSync: this.eventDetailsDialogVisible,
        };
    },
    components: {
        Card,
    },
    props: {
        title: {
            type: String,
            default: "",
        },
        eventDetails: {
            type: Object,
            required: false,
        },
        eventDetailsDialogVisible: {
            type: Boolean,
            default: false,
        },
        eventDetailsLoading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        cancel() {
            this.$emit("close");
        },
        formatValues(values) {
            if (!values) return "";
            try {
                const parsedValues = JSON.parse(values);
                for (const key in parsedValues) {
                    if (parsedValues[key] === "(null)") {
                        parsedValues[key] = "";
                    }
                }
                return parsedValues;
            } catch (error) {
                console.error("Error parsing values:", error);
                return "Error parsing values";
            }
        },
    },
    computed: {
        hasChanges() {
            return !!this.eventDetails.oldValues || !!this.eventDetails.newValues;
        },
        hasUpdatedValues() {
            return !!this.eventDetails.oldValues && !!this.eventDetails.newValues;
        },
        eventResponsibleType() {
            if (this.eventDetails) {
                return this.eventDetails.clientUserName !== "-" ? "Kund" : "Admin";
            } else return "";
        },
    },
};
</script>

<style scoped>
.event-details-comparison-container {
    display: flex;
    gap: 1rem;
}
.event-detail {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    width: 100%;
    font-size: 12px;
}
.event-detail-label {
    color: #1d3f67;
    font-weight: bold;
}
.event-detail-value {
    color: #1d3f67;
    font-size: 12px;
}
.event-details-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1 0em;
}
.event-details-dialog-title {
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #1d3f67 !important;
}
.event-details-table {
    width: 100%;
    border-collapse: collapse;
}
thead {
    border-bottom: 1px solid #b4c8e0;
}
tbody tr {
    border-bottom: 1px solid #b4c8e0;
    transition: background-color 0.2s ease;
}
th,
td {
    white-space: pre;
    padding-bottom: 1em;
    padding-right: 2em;
    min-width: 33%;
}
td {
    padding-top: 1em;
    font-size: 12px;
    color: #1d3f67;
}
tr > th {
    font-weight: bold;
    color: #1d3f67;
    font-size: 14px;
}

.event-details-no-change {
    font-weight: 600;
    color: #808080;
    font-size: 14px;
}
div >>> .el-dialog {
    background-color: #fafafa;
    min-height: 500px;
}
</style>
