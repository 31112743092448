<template>
    <div>
        <div class="flex relative justify-between p-3 mb-3 border-b border-gray-200">
            <div class="flex">
                <ClientsSearch @on-selected="onClientChange" :selected="preSelectedClient" />
                <div class="absolute flex items-center right-0">
                    <el-checkbox @change="showTestChanged" style="margin-right: 1rem" v-model="test">Visa test</el-checkbox>
                    <el-button type="primary" @click="sendKyc" :loading="$waiting.is('sending_reminder')" :disabled="selectedClientsData.length === 0">Skicka formulär</el-button>
                    <!-- <el-button type="primary" :loading="$waiting.is('sending_to_monitor_list')" :disabled="selectedClientsData.length === 0">Flytta till Ingen åtgärd krävs</el-button> -->
                    <el-button type="danger" @click="blockClient" :loading="$waiting.is('sending_to_monitor_list')" :disabled="selectedClientsData.length === 0">Blockera</el-button>
                </div>
            </div>
        </div>
        <StatusSelect ref="statusSelect" v-if="workMode === 'advanced'" @searchParametersChanged="searchParametersChanged" />
    </div>
</template>
<script>
import queryString from "query-string";
import Api from "../kycByClient.api.js";
export default {
    data() {
        return {
            workMode: "advanced",
            preSelectedClient: {},
            test: false,
        };
    },

    components: {
        StatusSelect: () => import("./kycByClientNewStatusSelect.vue"),
        ClientsSearch: () => import("@/components/clientsSearch.vue"),
    },
    props: {
        selectedClientsData: {
            type: Array,
            default: () => [],
        },
    },
    created() {
        this.checkPreselectedClient();
        const { test } = queryString.parse(window.location.search, { sort: false });
        this.test = test === "true";
    },
    methods: {
        showTestChanged() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.test = this.test;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("searchParametersChanged");
            setTimeout(() => {
                this.$refs.statusSelect.getAll();
            }, 100);
        },
        async sendKyc() {
            await Api.sendKycQuestionnaire(this.selectedClientsData);
            this.$emit("searchParametersChanged");
            setTimeout(() => {
                this.$refs.statusSelect.getAll();
            }, 100);
        },
        blockClient() {
            console.log("blockClient");
        },
        isDisabled() {
            if (this.selectedClientsData.length === 0) {
                return true;
            }
            return false;
        },
        checkPreselectedClient() {
            const { clientId } = queryString.parse(window.location.search, { sort: false });
            this.preSelectedClient = {
                value: clientId,
            };
        },
        async onClientChange(client) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.clientId = client.value;
            this.$router.replace({ query }).catch(() => {});
            this.searchParametersChanged();
        },
        searchInputChange() {
            // this.$emit("searchInputChange");
            this.$refs.statusSelect.getAll();
        },

        searchParametersChanged() {
            this.$emit("searchParametersChanged");
            setTimeout(() => {
                this.$refs.statusSelect.getAll();
            }, 100);
        },
    },
};
</script>
