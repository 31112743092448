import Vue from "vue";

export default {
    getNextReceipt: async (receiptId, search, status, currency, dateFrom, dateTo, clientId, test, category) => {
        try {
            const response = await Vue.axios.get(`receipts/${receiptId}/next`, {
                params: {
                    search,
                    status,
                    currency,
                    startDate: dateFrom,
                    endDate: dateTo,
                    clientId,
                    test,
                    category,
                },
            });
            return response.data;
        } catch (error) {
            console.log("🚀 ~ file: invoices.api.js ~ getNextReceipt: ~ error", error);
            return [];
        }
    },
    getAllCategoryNames: async () => {
        try {
            const response = await Vue.axios.get("categories/accountplans/names");
            return response.data;
        } catch (error) {
            console.log("🚀 ~ file: invoices.api.js ~ getAll: ~ error", error);
            return [];
        }
    },
    getAll: async (pageNumber, pageSize, search, sortBy, sortByType, status, currency, dateFrom, dateTo, clientId, test, category) => {
        try {
            const response = await Vue.axios.get(`receipts`, {
                params: {
                    pageNumber,
                    pageSize,
                    search,
                    sortBy,
                    sortType: sortByType,
                    status,
                    currency,
                    startDate: dateFrom,
                    endDate: dateTo,
                    clientId,
                    test,
                    category,
                },
            });
            return response;
        } catch (error) {
            console.log("🚀 ~ file: invoices.api.js ~ getAll: ~ error", error);
            return [];
        }
    },
    getCounts: async (search, currency, clientId, startDate, endDate, test, category) => {
        try {
            const { data } = await Vue.axios.get(`receipts/counts`, {
                params: {
                    search,
                    currency,
                    startDate,
                    endDate,
                    clientId,
                    test,
                    category,
                },
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: invoices.api.js ~ getCounts: ~ error", error);
            return [];
        }
    },
    getById: async id => {
        try {
            const { data } = await Vue.axios.get(`receipts/${id}`);
            return data;
        } catch (error) {
            return [];
        }
    },
    getAttatchments: async id => {
        try {
            const { data } = await Vue.axios.get(`receipts/attatchments/${id}`);
            return data;
        } catch (error) {
            return [];
        }
    },
    getIndividuals: async clientId => {
        try {
            const { data } = await Vue.axios.get(`clients/${clientId}/persons`);
            return data;
        } catch (error) {
            return [];
        }
    },
    saveReceipt: async (id, receiptData) => {
        try {
            const { data } = await Vue.axios.post(`receipts/${id}/save`, {
                ...receiptData,
            });
            return data;
        } catch (error) {
            return [];
        }
    },
    RegisterReceipt: async (id, receiptData, voucherData) => {
        try {
            const { data } = await Vue.axios.post(`receipts/${id}/book`, {
                receiptDto: receiptData,
                transactions: voucherData,
            });
            return data;
        } catch (error) {
            return [];
        }
    },
    DenyReceipt: async (id, commentData) => {
        try {
            const { data } = await Vue.axios.post(`receipts/${id}/deny`, commentData);
            return data;
        } catch (error) {
            return [];
        }
    },
    SupplementReceipt: async (id, commentData) => {
        try {
            const { data } = await Vue.axios.post(`receipts/${id}/supplement`, commentData);
            return data;
        } catch (error) {
            return [];
        }
    },
    DiscardReceipt: async (id, commentData) => {
        try {
            const { data } = await Vue.axios.post(`receipts/${id}/discard`, commentData);
            return data;
        } catch (error) {
            return [];
        }
    },
    GetEvents: async id => {
        try {
            const { data } = await Vue.axios.get(`receipts/${id}/events`);
            return data;
        } catch (error) {
            return [];
        }
    },
    GetMlResult: async id => {
        try {
            const { data } = await Vue.axios.get(`receipts/${id}/ml-result`);
            return data;
        } catch (error) {
            return {};
        }
    },

    getAllCategories: async companyType => {
        try {
            const response = await Vue.axios.get(`receipts/categories`, {
                params: {
                    companyType,
                },
            });
            return response.data;
        } catch (e) {
            console.log("🚀 ~ file: receipts.api.js ~ getAllCategories: ~ e", e);
            return [];
        }
    },
    RegisterHandlingTime: async (id, handlingTimeActionType, time) => {
        try {
            time = Math.floor(time);
            const { data } = await Vue.axios.post(`receipts/${id}/handlingTime`, {
                handlingTimeActionType,
                time,
            });
            return data;
        } catch (error) {
            return [];
        }
    },
    GetOpenFinancialYears: async clientId => {
        var response = await Vue.axios.get(`clients/${clientId}/financialyears`);
        return response.data;
    },
};
