<template>
    <div>
        <div class="card-tail">
            <TopMenu :selected-clients-data="selectedClients" @searchParametersChanged="syncDataWithQueryParameters" />
        </div>
        <div v-loading="$waiting.is('loading')" class="card-tail">
            <el-table @row-click="clickKycClient" :data="tableData" @selection-change="changeRowsSelection">
                <el-table-column type="selection" width="45" :selectable="isSelectable" />
                <el-table-column prop="clientNumber" label="Klient Nummer" sortable />
                <el-table-column prop="clientName" label="Klient" sortable />
                <el-table-column prop="lastUpdate" label="Senaste Kontroll" :formatter="formatDate" sortable />
                <el-table-column prop="kycByClientStatus" label="Status" sortable :formatter="formatStatusInteger" />
                <el-table-column prop="riskGradeManual" label="Riskklass" sortable v-slot="scope" width="100">
                    <div :class="['risk-grade', getRiskColor(scope.row.riskGradeManual)]">
                        {{ scope.row.riskGradeManual }}
                    </div>
                </el-table-column>
                <el-table-column prop="riskGradeAuto" label="Auto" sortable v-slot="scope" width="100">
                    <div :class="['risk-grade', getRiskColor(scope.row.riskGradeAuto)]">
                        {{ scope.row.riskGradeAuto }}
                    </div>
                </el-table-column>
            </el-table>
            <kycByClientModal
                @eventExecuted="kycEventExecuted"
                :status-enum-name="statusEnumName"
                :displayed-status-name="displayedStatusName"
                :client-id="selectedClient"
                :client-name="clientName"
                @close="closeClientKyc"
                :dialog-visible.sync="showClientKyc"
            />
        </div>
    </div>
</template>

<script>
import KycByClientNewTopMenu from "./KycByClientNewTopMenu.vue";
import queryString from "query-string";
import Api from "../kycByClient.api.js";
import Enum from "../kycByClient.enum.js";
import moment from "moment";
import kycByClientModal from "./kycByClientModal.vue";
export default {
    data() {
        return {
            showClientKyc: false,
            selectedClient: null,
            displayedStatusName: "",
            statusEnumName: "",
            tableData: [],
            kycStatusReverseMap: {},
            selectedClients: [],
            clientId: "",
            clientName: "",
            status: "",
            test: false,
            buttonsDisabled: false,
            selectableStatuses: ["notSent", "noActionRequired", "toSupplement", "supplemented", "denied", "needExtraCheck"],
        };
    },
    components: {
        TopMenu: KycByClientNewTopMenu,
        kycByClientModal,
    },
    created() {
        if (!this.isGlobalClientSelected) this.updateUrlAndGetAll();
    },
    methods: {
        kycEventExecuted() {
            this.closeClientKyc();
            this.updateUrlAndGetAll();
        },
        clickKycClient(row) {
            this.showClientKyc = !this.showClientKyc;
            this.clientName = `${row.clientNumber}, ${row.clientName}`;
            this.selectedClient = row.clientId;
            this.statusEnumName = Enum.kycByClientStatusNew[row.kycByClientStatus];
            const translatedString = this.translateStatusString(this.statusEnumName);
            this.displayedStatusName = translatedString;
        },
        closeClientKyc() {
            this.showClientKyc = false;
            this.selectedClient = null;
            this.displayedStatusName = "";
            this.statusEnumName = "";
            this.clientName = "";
        },
        changeRowsSelection(rows) {
            this.selectedClients = rows.map(item => item.clientId);
        },
        isSelectable(row) {
            const statusInt = row.kycByClientStatus;
            const statusString = Enum.kycByClientStatusNew[statusInt];
            return this.selectableStatuses.includes(statusString);
        },
        formatDate(row) {
            const date = row.lastUpdate;
            return moment(date).format("YYYY-MM-DD");
        },
        formatStatusInteger(row, column, cellValue) {
            const translatedString = this.translateStatusString(Enum.kycByClientStatusNew[cellValue]);
            return translatedString;
        },
        translateStatusString(string) {
            const translation = {
                notSent: "Ej utskickade",
                sentToClient: "Utkast",
                inProgress: "Pågående",
                submitted: "Inskickad",
                noActionRequired: "Ingen åtgärd krävs",
                toSupplement: "Komplettering",
                supplemented: "Kompletterad",
                needExtraCheck: "Extra Check",
                denied: "Nekad",
                blocked: "Blockerad",
            };

            return translation[string];
        },

        syncDataWithQueryParameters() {
            setTimeout(() => {
                this.updateUrlAndGetAll();
            }, 200);
        },
        showTest() {
            this.showTestClients = !this.showTestClients;
        },
        updateUrlAndGetAll() {
            const { status, clientId, test } = queryString.parse(window.location.search, { sort: false });
            this.test = test;
            this.clientId = clientId;
            this.status = status && status !== "all" ? status : "all";
            this.getKycListData();
        },
        async getKycListData() {
            this.$waiting.start("loading");
            var response = await Api.getClientKycListAll(this.status, this.clientId, this.test);
            this.tableData = response.data;
            this.$waiting.end("loading");
        },
        getRiskColor(riskGrade) {
            switch (riskGrade) {
                case 1:
                    return "risk-grade-green";
                case 2:
                    return "risk-grade-orange";
                case 3:
                    return "risk-grade-red";
                case 4:
                    return "risk-grade-red";
                default:
                    return "risk-grade-grey";
            }
        },
    },
    computed: {
        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>

<style scoped>
.risk-grade {
    border-radius: 3px;
    color: #fff;
    font-weight: bold;
    width: 25px;
    height: 25px;
    text-align: center;
}
.risk-grade-green {
    background-color: #4caf50;
}
.risk-grade-orange {
    background-color: #ff9800;
}
.risk-grade-red {
    background-color: #f44336;
}
.risk-grade-grey {
    background-color: #9e9e9e;
}
</style>
