<template>
    <el-dialog width="30%" :visible.sync="riskClassDialogVisible" v-on:update:visibleSync="visibleSync = $event" :before-close="cancel" append-to-body>
        <span class="risk-grade-change-title" slot="title">Ändra riskklass - {{ clientName }}</span>
        <div class="risk-grade-change-container">
            <p class="info-text">Aktuell riskklass: {{ riskClass }}</p>
            <div class="risk-grade-inputs-container">
                <el-input-number :min="1" :max="3" v-model="newRiskClass" />
                <el-input required type="textarea" :autosize="{ minRows: 2, maxRows: 8 }" placeholder="Motivering för riskklassändring" v-model="riskChangeComment" />
            </div>
            <div class="risk-grade-buttons-container">
                <el-button @click="cancel">Avbryt</el-button>
                <el-button type="primary" @click="changeRiskClass()">Bekräfta ändring</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import Api from "../kycByClient.api.js";
export default {
    data() {
        return {
            visibleSync: this.riskClassDialogVisible,
            riskChangeComment: "",
            newRiskClass: this.riskClass,
            currentRiskClass: undefined,
        };
    },
    props: {
        riskClassDialogVisible: {
            type: Boolean,
            default: false,
        },
        clientName: {
            type: String,
            default: "",
        },
        riskClass: {
            type: Number,
            default: 0,
        },
        clientId: {
            type: String,
            default: undefined,
        },
    },
    methods: {
        async changeRiskClass() {
            try {
                await Api.changeClientKycRiskGrade(this.clientId, this.newRiskClass, this.riskChangeComment);
                this.$notify.success({ title: "Riskklass ändrad" });
                this.$emit("riskGradeChanged", this.newRiskClass);
            } catch (error) {
                this.$notify.error({ title: `Ett fel uppstod vid riskklass ändringen` });
            }
        },

        cancel() {
            this.$emit("close");
            this.newRiskClass = this.riskClass;
            this.riskChangeComment = "";
        },
    },
    // Other component options (e.g., computed properties, lifecycle hooks) can be added here
};
</script>

<style scoped>
.risk-grade-change-title {
    font-weight: 600;
    color: #1d3f67;
    font-size: 18px;
}
.info-text {
    color: #617d7f;
    font-size: 14px;
}
.risk-grade-change-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: 100%;
}
.risk-grade-inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.risk-grade-buttons-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 1rem;
}
</style>
